<template>
  <div class="product-detail" v-loading="loading">
    <div v-if="product" class="product-container">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/products' }">产品列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{ product.name }}</el-breadcrumb-item>
      </el-breadcrumb>
      
      <div class="product-main">
        <!-- 产品图片 -->
        <div class="product-gallery">
          <el-carousel trigger="click" height="400px" indicator-position="outside">
            <el-carousel-item v-for="(image, index) in product.images" :key="index">
              <img :src="image" :alt="product.name" class="gallery-image">
            </el-carousel-item>
          </el-carousel>
        </div>
        
        <!-- 产品信息 -->
        <div class="product-info">
          <h1 class="product-name">{{ product.name }}</h1>
          <div class="product-category">分类：{{ product.category }}</div>
          <div class="product-price">¥{{ product.price.toFixed(2) }}</div>
          
          <!-- SKU选择 -->
          <div class="product-sku">
            <h3>规格选择</h3>
            <el-radio-group v-model="selectedSku">
              <el-radio v-for="sku in product.skus" :key="sku.id" :label="sku.id">
                {{ sku.name }} - ¥{{ sku.price.toFixed(2) }}
                <span v-if="sku.stock <= 0" class="out-of-stock">(缺货)</span>
              </el-radio>
            </el-radio-group>
          </div>
          
          <!-- 数量选择 -->
          <div class="product-quantity">
            <h3>数量</h3>
            <el-input-number v-model="quantity" :min="1" :max="99"></el-input-number>
          </div>
          
          <!-- 按钮 -->
          <div class="product-actions">
            <el-button type="primary" size="large" icon="el-icon-shopping-cart-2">加入购物车</el-button>
            <el-button type="danger" size="large">立即购买</el-button>
          </div>
        </div>
      </div>
      
      <!-- 产品详情 -->
      <div class="product-description">
        <h2>产品详情</h2>
        <div class="description-content">
          <p>{{ product.description }}</p>
          <div class="detail-images">
            <img v-for="(image, index) in product.images" :key="index" :src="image" :alt="product.name">
          </div>
        </div>
      </div>
    </div>
    
    <el-empty v-else-if="!loading" description="产品不存在"></el-empty>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductDetail',
  data() {
    return {
      loading: true,
      selectedSku: null,
      quantity: 1
    }
  },
  computed: {
    ...mapGetters('products', ['productDetail']),
    product() {
      return this.productDetail
    }
  },
  created() {
    this.loadProductData()
  },
  methods: {
    ...mapActions('products', ['fetchProductById']),
    async loadProductData() {
      this.loading = true
      try {
        const productId = parseInt(this.$route.params.id)
        await this.fetchProductById(productId)
        if (this.product && this.product.skus && this.product.skus.length > 0) {
          this.selectedSku = this.product.skus[0].id
        }
      } catch (error) {
        this.$message.error('获取产品详情失败')
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.product-detail {
  margin-bottom: 40px;
}

.product-container {
  margin-top: 20px;
}

.product-main {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 30px;
}

.product-gallery {
  flex: 1;
  min-width: 300px;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-info {
  flex: 1;
  min-width: 300px;
}

.product-name {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.product-category {
  color: #666;
  margin-bottom: 15px;
}

.product-price {
  font-size: 28px;
  color: #f56c6c;
  font-weight: bold;
  margin-bottom: 20px;
}

.product-sku, .product-quantity {
  margin-bottom: 20px;
}

.product-sku h3, .product-quantity h3 {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.out-of-stock {
  color: #f56c6c;
  margin-left: 5px;
}

.product-actions {
  margin-top: 30px;
  display: flex;
  gap: 15px;
}

.product-description {
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid #eee;
}

.product-description h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
}

.description-content {
  color: #666;
  line-height: 1.6;
}

.detail-images {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.detail-images img {
  width: 100%;
  border-radius: 8px;
}
</style> 